export enum PublicationType {
  PublicationUkrainian = 1,
  PublicationForeign,
  Patent,
  Monograph,
  MonographSection,
}

export enum SvrMember {
  Head = 1,
  Reviewer,
  Opponent,
}

export const SvrMemberTypeMap = new Map<
  SvrMember,
  'head' | 'reviewer' | 'opponent'
>([
  [1, 'head'],
  [2, 'reviewer'],
  [3, 'opponent'],
]);

export enum DegreeType {
  PhD = 1,
  KN,
  DN,
  DrHab,
}

export enum DefenseState {
  Annulled = 0,
  Planned,
  Suspended,
  FinishedDegreeAwarded,
  FinishedDegreeDenied,
  ThesisWithdrawn,
  DegreeCancelledByHei,
  DegreeCancelledByNaqa,
}

export enum MessageType {
  CreateDefense = 'createDefense',
  ModifyDefense = 'modifyDefense',
  DefenseTimePlace = 'defenseTimePlace',
  Complaint = 'complaint',
  MON = 'mon',
  DefenseResults = 'defenseResults',
  ThesisWithdrawal = 'thesisWithdrawal',
  HeiCancellation = 'heiCancellation',
  NaqaComplaint = 'naqaComplaint',
  NaqaMeeting = 'naqaMeeting',
  NaqaCommitteeDecision = 'naqaCommitteeDecision',
  NaqaDecision = 'naqaDecision',
  NaqaResetState = 'naqaResetState',
  NaqaPlanState = 'naqaPlanState',
  Annulment = 'annulment',
}
