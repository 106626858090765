<div class="page-center-container">

  <h2>Відомості про захист</h2>

  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div>
      <mat-chip-list [selectable]="false">
        <mat-chip [selectable]="false">ID {{ defenseResponse.defense.defenseId }}</mat-chip>
        <app-defense-state-chip [defenseState]="defenseResponse.defense.state"></app-defense-state-chip>
      </mat-chip-list>
    </div>
    <div matTooltip="Для подання повідомлень потрібна аутентифікація з використанням електронного підпису"
         [matTooltipDisabled]="messagesButtonEnabled$ | ngrxPush">
      <button mat-raised-button
              color="primary"
              [matMenuTriggerFor]="actionsMenu"
              [disabled]="!(messagesButtonEnabled$ | ngrxPush)">
        Подати повідомлення
      </button>
    </div>
  </div>

  <mat-menu #actionsMenu xPosition="before">
<!--    Повідомлення ЗВО-->
    <button mat-menu-item
            *ngIf="showHeiMessages$ | ngrxPush"
            [disabled]="!(modifyDefenseAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/modify-defense/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>published_with_changes</mat-icon>
      <span>Зміни у складі разової ради / посиланнях</span>
    </button>

    <button mat-menu-item
            *ngIf="showHeiMessages$ | ngrxPush"
            [disabled]="!(defenseTimePlaceAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/defense-time-place/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>schedule</mat-icon>
      <span>Дата, час і місце захисту</span>
    </button>

    <button mat-menu-item
            *ngIf="showHeiMessages$ | ngrxPush"
            [disabled]="!(defenseResultsAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/defense-results/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon class="material-icons-outlined">task</mat-icon>
      <span>Результати захисту</span>
    </button>

    <button mat-menu-item
            *ngIf="showHeiMessages$ | ngrxPush"
            [disabled]="!(thesisWithdrawalAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/thesis-withdrawal/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>work_outline</mat-icon>
      <span>Зняття дисертації з захисту</span>
    </button>

    <button mat-menu-item
            *ngIf="showHeiMessages$ | ngrxPush"
            [disabled]="!(heiCancellationAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/hei-cancellation/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon class="material-icons-outlined">cancel</mat-icon>
      <span>Скасування рішення про присудження (заклад)</span>
    </button>

    <button mat-menu-item
            *ngIf="showHeiMessages$ | ngrxPush"
            [disabled]="!(annulmentAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/annulment/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon class="material-icons-outlined">block</mat-icon>
      <span>Відкликання повідомлення про утворення ради</span>
    </button>

<!--    Повідомлення НА-->
    <!--[disabled]="!(naqaMessagesAllowed$ | ngrxPush)"-->

    <button mat-menu-item
            *ngIf="showNaqaMessages$ | ngrxPush"
            [routerLink]="'/notifications/naqa-complaint/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon class="material-icons-outlined">mail_outline</mat-icon>
      <span>Надходження повідомлення/скарги до НА</span>
    </button>

    <button mat-menu-item
            *ngIf="showNaqaMessages$ | ngrxPush"
            [disabled]="!(naqaMessagesAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/naqa-meeting/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>schedule</mat-icon>
      <span>Засідання НА/комітету НА</span>
    </button>

    <button mat-menu-item
            *ngIf="showNaqaMessages$ | ngrxPush"
            [disabled]="!(naqaMessagesAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/naqa-committee-decision/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>description</mat-icon>
      <span>Рішення комітету НА</span>
    </button>

    <button mat-menu-item
            *ngIf="showNaqaMessages$ | ngrxPush"
            [disabled]="!(naqaMessagesAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/naqa-decision/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>description</mat-icon>
      <span>Рішення НА</span>
    </button>

    <button mat-menu-item
            *ngIf="showNaqaMessages$ | ngrxPush"
            [disabled]="!(naqaPlanStateAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/naqa-plan-state/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>description</mat-icon>
      <span>Повідомлення про зміну стану захисту (НА)</span>
    </button>

    <button mat-menu-item
            *ngIf="showNaqaMessages$ | ngrxPush"
            [routerLink]="'/notifications/naqa-reset-state/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon>description</mat-icon>
      <span>Повідомлення про оприлюднення (НА) результатів захисту</span>
    </button>

<!--    Повідомлення МОН-->
    <button mat-menu-item
            *ngIf="showMonMessages$ | ngrxPush"
            [disabled]="!(monAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/mon/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon class="material-icons-outlined">pause_circle</mat-icon>
      <span>Зупинення роботи разової ради (МОН)</span>
    </button>

<!--    Скарга-->
    <button mat-menu-item
            *ngIf="showComplaint$ | ngrxPush"
            [disabled]="!(complaintAllowed$ | ngrxPush)"
            [routerLink]="'/notifications/complaint/' + defenseResponse.defense.defenseId.toString()">
      <mat-icon class="material-icons-outlined">feedback</mat-icon>
      <span>Повідомлення щодо невідповідності складу разової ради</span>
    </button>

  </mat-menu>

  <h3>Загальні відомості</h3>

  <app-name-value-table [dataSource]="generalData(defenseResponse)"></app-name-value-table>

  <div style="text-align: right">
    <a mat-button color="primary" [href]="'https://registry.naqa.gov.ua/#/op/' + defenseResponse.defense.op.opId.toString()" target="_blank">
      <mat-icon>link</mat-icon>
      &nbsp;
      Відомості про ОП (NAQA.Registry)
    </a>
  </div>

  <h3>Дисертація</h3>

  <app-name-value-table [dataSource]="thesisData(defenseResponse)"></app-name-value-table>

  <div style="text-align: right">
    <a mat-button color="primary" [href]="defenseResponse.defense.thesisLink" target="_blank">
      <mat-icon>link</mat-icon>
      &nbsp;
      Переглянути дисертацію
    </a>
  </div>

  <h4>Публікації здобувача за темою дисертації</h4>

  <app-publications-display
    [receivedFromApi]="true"
    [changeable]="false"
    [publications]="defenseResponse.defense.thesisPublications">
  </app-publications-display>


  <h3>Захист</h3>

  <app-name-value-table [dataSource]="defenseData(defenseResponse)"></app-name-value-table>

  <div style="text-align: right">
    <a mat-button color="primary" [href]="defenseResponse.defense.defenseBroadcastLink" target="_blank">
      <mat-icon>link</mat-icon>
      &nbsp;
      Онлайн трансляція захисту
    </a>
  </div>


  <h3>Разова рада</h3>

  <app-svr-members-show [receivedFromApi]="true" [svrMembers]="defenseResponse.defense.svrMembers" [changeable]="false"></app-svr-members-show>


  <h3>Історія</h3>

  <app-name-value-table [dataSource]="historyData(defenseResponse)"></app-name-value-table>


  <div *ngIf="defenseResponse.defense.appealsCommitteeMeetingDate !== undefined || defenseResponse.defense.ethicsCommitteeMeetingDate !== undefined || defenseResponse.defense.naqaMeetingDate !== undefined">
    <h3>Засідання Національного агентства і його комітетів</h3>

    <app-name-value-table [dataSource]="meetingsData(defenseResponse)"></app-name-value-table>
  </div>


  <h3>Повідомлення</h3>

  <app-messages-display [messages]="defenseResponse.messages" [smallIcons]="true" [showLinkToDefense]="false"></app-messages-display>

</div>


