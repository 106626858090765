import { Injectable } from '@angular/core';
import {
  DefenseState,
  DegreeType,
  MessageType,
  PublicationType,
  SvrMember,
} from '../../misc/enums';

export type TUsedMap =
  | 'publication'
  | 'svrMember'
  | 'degree'
  | 'defenseState'
  | 'message';

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  /**
   * Типи публікацій. Застосовується у відомостях про здобувачів та членів РСВР.
   */
  readonly PublicationTypeMap: Map<PublicationType, string> = new Map([
    [PublicationType.PublicationUkrainian, 'Публікація в українському періодичному фаховому виданні'],
    [PublicationType.PublicationForeign, 'Публікація в іноземному періодичному індексованому в WoS/Scopus виданні'],
    [PublicationType.Patent, 'Патент на винахід'],
    [PublicationType.Monograph, 'Монографія'],
    [PublicationType.MonographSection, 'Розділ у монографії'],
  ]);

  /**
   * Типи членів РСВР.
   */
  readonly SvrMemberMap: Map<SvrMember, string> = new Map([
    [SvrMember.Head, 'Голова разової ради'],
    [SvrMember.Reviewer, 'Рецензент'],
    [SvrMember.Opponent, 'Офіційний опонент'],
  ]);

  /**
   * Типи наукових ступенів. Застосовується у відомостях про членів РСВР.
   */
  readonly DegreeTypeMap: Map<DegreeType, string> = new Map([
    [DegreeType.PhD, 'Доктор філософії'],
    [DegreeType.KN, 'Кандидат наук'],
    [DegreeType.DN, 'Доктор наук'],
    [DegreeType.DrHab, 'Доктор габілітований'],
  ]);

  /**
   * Статуси захисту.
   */
  readonly DefenseStateMap: Map<DefenseState, string> = new Map([
    [DefenseState.Planned, 'Захист планується'],
    [DefenseState.Suspended, 'Зупинено МОН'],
    [DefenseState.FinishedDegreeAwarded, 'Ступінь присуджено'],
    [DefenseState.FinishedDegreeDenied, 'Відмова у присудженні'],
    [DefenseState.ThesisWithdrawn, 'Знято з захисту'],
    [DefenseState.DegreeCancelledByHei, 'Скасовано закладом'],
    [DefenseState.DegreeCancelledByNaqa, 'Скасовано НА'],
    [DefenseState.Annulled, 'Відкликано закладом'],
  ]);

  /**
   * Типи повідомлень.
   */
  readonly MessageTypeMap: Map<MessageType, string> = new Map([
    [MessageType.CreateDefense, 'Повідомлення про утворення разової ради'],
    [
      MessageType.ModifyDefense,
      'Повідомлення про зміни у складі разової ради та/або посилань',
    ],
    [
      MessageType.DefenseTimePlace,
      'Повідомлення про дату, час і місце проведення публічного захисту',
    ],
    [MessageType.MON, 'Повідомлення про зупинення роботи разової ради'],
    [
      MessageType.Complaint,
      'Повідомлення щодо невідповідності складу разової ради вимогам законодавства',
    ],
    [MessageType.DefenseResults, 'Повідомлення про результати захисту (заклад)'],
    [
      MessageType.ThesisWithdrawal,
      'Повідомлення про зняття дисертації з захисту за заявою здобувача',
    ],
    [
      MessageType.HeiCancellation,
      'Повідомлення про скасування рішення про присудження ступеня (заклад)',
    ],
    [
      MessageType.NaqaComplaint,
      'Повідомлення про надходження повідомлення/скарги до Національного агентства',
    ],
    [
      MessageType.NaqaMeeting,
      'Повідомлення про засідання Національного агентства',
    ],
    [
      MessageType.NaqaCommitteeDecision,
      'Повідомлення про рішення комітету Національного агентства',
    ],
    [
      MessageType.NaqaDecision,
      'Повідомлення про рішення Національного агентства',
    ],
    [
      MessageType.NaqaResetState,
      'Повідомлення про оприлюднення Національним агентством результатів захисту',
    ],
    [
      MessageType.NaqaPlanState,
      'Повідомлення про зміну стану захисту Національним агентством',
    ],
    [
      MessageType.Annulment,
      'Повідомлення про відкликання повідомлення про утворення разової ради',
    ],
  ]);

  /**
   * Мапа мап.
   */
  readonly MapOfMaps: Map<TUsedMap, Map<any, string>> = new Map<
    TUsedMap,
    Map<any, string>
  >([
    ['publication', this.PublicationTypeMap],
    ['svrMember', this.SvrMemberMap],
    ['degree', this.DegreeTypeMap],
    ['defenseState', this.DefenseStateMap],
    ['message', this.MessageTypeMap],
  ]);

  constructor() {}

  queryMap(map: TUsedMap, key: any): string | undefined {
    return this.MapOfMaps.get(map)?.get(key);
  }

  getEntries(map: TUsedMap) {
    return this.MapOfMaps.get(map)?.entries();
  }
}
